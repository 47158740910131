import axios, { AxiosResponse } from "axios";
import axiosRetry from "axios-retry";
import isOnline from 'is-online';
import { User } from "../../../domain/Types/User";
import { BASE_URL } from "../../../domain/Types/Config";
import { DeliveryDetails } from "../../../domain/Types/DeliveryDetails";
import { Payment } from "../../../domain/Types/Payment";
import { paymentoption } from "../../../domain/Types/paymentoption";

axiosRetry(axios, {
  retries: 3, // Number of retries
  retryDelay: axiosRetry.exponentialDelay, // Exponential backoff strategy
  shouldResetTimeout: true // Reset timeout on retries
});

export const LoginUser = async (user: User): Promise<User> => {
  try {
    let token = localStorage.getItem('fcmToken');

    /*if (!token) {
        token = Math.random().toString(36).substring(2); // Generate a random string
        localStorage.setItem('fcmToken', token); // Store the random string as the token
    }*/
    
    // Check for internet connectivity
    const online = await isOnline();
    if (!online) {
      console.log("No internet connection. Retrying...");
      return LoginUser(user); // Retry fetching data
    }

    const url = `${BASE_URL}/login`;
    const response: AxiosResponse<User> = await axios.post(
        url,{
            email:user.email,    
            password:user.password,
            rememberme:1,
            fcmtoken:token
          }

    );
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};


export const RegisterUser = async (user: User): Promise<User> => {
    try {
      // Check for internet connectivity
      const online = await isOnline();
      if (!online) {
        console.log("No internet connection. Retrying...");
        return RegisterUser(user); // Retry fetching data
      }
      
      console.log(user);
      const url = `${BASE_URL}/register`;
      const response: AxiosResponse<User> = await axios.post(url,user);
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };

  export const ForgotPassword = async (user: User): Promise<User> => {
    try {
      // Check for internet connectivity
      const online = await isOnline();
      if (!online) {
        console.log("No internet connection. Retrying...");
        return RegisterUser(user); // Retry fetching data
      }
      
      console.log(user);
      const url = `${BASE_URL}/register`;
      const response: AxiosResponse<User> = await axios.post(url,user);
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };

  export const UpdateUser = async (user: User): Promise<User> => {
    try {
      // Check for internet connectivity
      const online = await isOnline();
      if (!online) {
        console.log("No internet connection. Retrying...");
        return UpdateUser(user); // Retry fetching data
      }
        
      const url = `${BASE_URL}/register`;
      const response: AxiosResponse<User> = await axios.post(url,user);
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };  


  export const UpdatePaymentDetails = async (paymentopt: paymentoption): Promise<paymentoption> => {
    try {
      // Check for internet connectivity
      const online = await isOnline();
      if (!online) {
        console.log("No internet connection. Retrying...");
        return UpdatePaymentDetails(paymentopt); // Retry fetching data
      }
        
      const url = `${BASE_URL}/paymentoption`;
      const response: AxiosResponse<paymentoption> = await axios.post(url,paymentopt);
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }; 

  export const UpdateDeliveryDetails = async (deliveryDetails: DeliveryDetails): Promise<DeliveryDetails> => {
    try {
      // Check for internet connectivity
      const online = await isOnline();
      if (!online) {
        console.log("No internet connection. Retrying...");
        return UpdateDeliveryDetails(deliveryDetails); // Retry fetching data
      }
        
      const url = `${BASE_URL}/SaveDeliveryDetails`;
      const response: AxiosResponse<DeliveryDetails> = await axios.post(url,deliveryDetails);
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };  

  
export const ForgotUserPassword = async (): Promise<User[]> => {
    try {
      // Check for internet connectivity
      const online = await isOnline();
      if (!online) {
        console.log("No internet connection. Retrying...");
        return ForgotUserPassword(); // Retry fetching data
      }
  
      const url = `${BASE_URL}/paymentdetails`;
      const response: AxiosResponse<User[]> = await axios.get(url);
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };


  export const UserTwoFactorAuthentication = async (): Promise<User[]> => {
    try {
      // Check for internet connectivity
      const online = await isOnline();
      if (!online) {
        console.log("No internet connection. Retrying...");
        return UserTwoFactorAuthentication(); // Retry fetching data
      }
  
      const url = `${BASE_URL}/paymentdetails`;
      const response: AxiosResponse<User[]> = await axios.get(url);
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };