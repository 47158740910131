import { useState } from 'react';
import {
  TextField,
  IconButton,
  Dialog,
  DialogContent,
  CircularProgress,
  Backdrop,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { fetchBusinessbyCategory } from '../../../../infrastructure/api/Business/BusinessAPI';
import { Search } from '../../../../domain/Types/Search';
import { Business } from '../../../../domain/Types/Business'; // Assuming you have a Business type
import { useNavigate } from 'react-router-dom'; // Assuming you're using react-router for navigation

const MobileSearchBar = () => {
  const [searchText, setSearchText] = useState<string>(''); // Holds the current search input text
  const [loading, setLoading] = useState<boolean>(false); // Loader state
  const [openLoader, setOpenLoader] = useState<boolean>(false); // Modal loader state
  const navigate = useNavigate(); // Hook for navigation
  const customColor = '#e88824';

  // Function to handle API call to fetch search results based on the input query
  const fetchSearchResults = async (query: string) => {
    const search: Search = {
      search: query,
      slug: "",
      BusinessCategory: query, // Adjust as needed
      location: '',
      foodtype: '',
      minimumPrice: 0,
      maximumprice: 0,
    };

    setLoading(true); // Set loading to true when starting the search
    setOpenLoader(true); // Open the modal loader

    try {
      const response = await fetchBusinessbyCategory(search); // Call the API

      if (Array.isArray(response) && response.length > 0) {
        // Redirect to the results page with the search results
        navigate('/FoodhutSearch', { state: { searchResults: response } });
      } else {
        // Optionally handle no results found
        console.log('No results found.');
      }
    } catch (error) {
      console.error('Error fetching search results:', error);
    } finally {
      setLoading(false); // Set loading to false when done
      setOpenLoader(false); // Close the modal loader
    }
  };

  // Handle search when button is clicked or Enter key is pressed
  const handleSearch = () => {
    if (searchText.trim().length > 0) {
      fetchSearchResults(searchText);
    }
  };

  // Handle key down event to check for Enter key
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearch(); // Trigger search on Enter key
    }
  };

  return (
    <div className="mobile-search-bar" style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <TextField
        variant="outlined"
        size="small"
        placeholder="Search"
        fullWidth
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)} // Directly handle input change
        onKeyDown={handleKeyDown} // Trigger search on Enter key
      />
      <IconButton onClick={handleSearch} disabled={loading}>
        <SearchIcon />
      </IconButton>

      {/* Backdrop for Modal Loader */}
      <Backdrop open={openLoader} style={{ zIndex: 9999 }}>
        <DialogContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <CircularProgress size={60} sx={{ color: customColor }} />
          <Typography variant="h6" style={{ marginTop: '16px', color: '#fff' }}>
            Searching...
          </Typography>
        </DialogContent>
      </Backdrop>
    </div>
  );
};

export default MobileSearchBar;