import React, { useState } from 'react';
import { Box, Modal, Button, IconButton, Rating, Chip } from '@mui/material';
import { AddShoppingCart, Close as CloseIcon, Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material';
import { Menu } from '../../../domain/Types/Menu';
import { ShoppingCart } from '../../../domain/Types/ShoppingCart';
import { ShoppingCartState } from '../../../utilities/Context/ShoppingCartContext';

interface CustomModalProps {
    open: boolean;
    handleClose: () => void;
    selectedItem: Menu | null;
    userId: number;
}

const AddToCartModal: React.FC<CustomModalProps> = ({ open, handleClose, selectedItem, userId }: CustomModalProps) => {
    const { dispatch } = ShoppingCartState();
    const [quantity, setQuantity] = useState<number>(1);

    const handleAddToCart = (menu: Menu) => {
        console.log(menu);
        const totalAmount = menu.price * quantity;
        const cartItem: ShoppingCart = {
            id: menu.id,
            user_id: userId,
            session_id: menu.business_id,
            order_id: 1,
            menu_id: menu.id,
            imagepath: menu.image_path,
            status: menu.description,
            quantity: quantity,
            total: totalAmount,
            name: menu.name,
            price: menu.price,
            productattributevalues: [],
            businessId: menu.business_id,
            businessName: menu.business.name
        };

        dispatch({ type: "ADD_TO_CART", item: cartItem });
        handleClose(); // Close modal after adding to cart
    };

    const incrementQuantity = () => {
        setQuantity((prev) => prev + 1);
    };

    const decrementQuantity = () => {
        if (quantity > 1) {
            setQuantity((prev) => prev - 1);
        }
    };

    return (
        <Modal open={open} onClose={handleClose} closeAfterTransition>
            <Box
                sx={{
                  position: 'absolute',
                  top: 40,
                  left: 70,
                  width: '90vw',
                  height: '90vh',
                  maxWidth: '90%',
                  maxHeight: '90%',
                  bgcolor: 'background.paper',
                  border: 'none',
                  boxShadow: 24,
                  p: 4,
                  overflowY: 'auto',
                  borderRadius: '16px',
                }}
            >
                {/* Close button at top-right corner */}
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>

                {selectedItem && (
                    <>
                        <div className="grid sm:px-10 lg:grid-cols-2 lg:px-20 xl:px-32">
                            <div className="mt-10 bg-gray-50 px-4 pt-8 lg:mt-0">
                                {/* Add image at the top */}
                                <img
                                    src={`https://app.foodhut.mw/Admin/public/images/${selectedItem.image_path}`}
                                    alt={selectedItem.name}
                                    className="w-full h-48 object-cover rounded-md"
                                />
                                <p className="text-xl font-bold border-b mt-4">Details</p>
                                <div>
                                    <p><span className="text-lg font-medium">Description: </span><span className="text-lg">{selectedItem.description}</span></p>
                                    <p><span className="text-lg font-medium">Rating: </span><span className="text-lg">
                                        <Rating value={selectedItem.average_rating} precision={0.5} readOnly />
                                      </span></p>
                                    <p><span className="text-lg font-medium">Price:</span><span className="text-lg">
                                        MK {selectedItem.price.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                      </span></p>
                                </div>
                                <p className="text-xl border-t font-bold">Ingredients</p>
                                <label htmlFor="card-holder" className=" mb-1 block text-sm font-medium">{selectedItem.description}</label>
                                <p className="text-xl border-t mt-2 font-bold">FoodStore Details</p>
                                <label htmlFor="card-holder" className="mb-1 block text-xl font-medium">{selectedItem.business.name}</label>
                                <label htmlFor="card-holder" className="mb-1 block text-sm font-medium">{selectedItem.business.description}</label>
                            </div>

                            <div className="px-4 rounded-lg border">
                                <p className="text-xl font-medium">Item Summary</p>
                                <div className="mt-8 space-y-3 rounded-lg border bg-white px-2 sm:px-6">
                                    <div key={selectedItem.id} className="flex flex-col rounded-lg bg-white sm:flex-row">
                                        <div className="flex w-full flex-col px-4">
                                          <div className="flex flex-row mt-2 items-center justify-between">
                                            <span className="float-right pr-4">
                                              <Chip
                                                  label={selectedItem.status === "available" ? "Available" : "Not Available"}
                                                  sx={{
                                                      backgroundColor: selectedItem.status === "available" ? 'green' : 'red',
                                                      color: 'white',
                                                      fontWeight: 'bold',
                                                  }}
                                                  size="small"
                                                  className="float-left"
                                              />
                                              <span className="font-semibold pl-2">{selectedItem.name}</span>
                                            </span>  
                                              <span className="font-semibold">(<span className="font-bold">
                                              MK {(selectedItem.price).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                              </span>)
                                            </span>
                                          </div>                                    
                                                {/* Quantity controls and total */}
                                                <div className="flex items-center justify-between">
                                                    <div className="flex items-center">
                                                        <IconButton onClick={decrementQuantity} aria-label="decrease quantity">
                                                            <RemoveIcon />
                                                        </IconButton>
                                                        <span className="text-lg font-bold mx-2">{quantity}</span>
                                                        <IconButton onClick={incrementQuantity} aria-label="increase quantity">
                                                            <AddIcon />
                                                        </IconButton>
                                                    </div>
                                                    <span className="text-lg font-bold">Total: 
                                                    MK {(selectedItem.price * quantity).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                    </span>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-6 border-t border-b py-2">
                                    <div className="flex items-center justify-between">
                                        <p className="text-sm font-medium text-gray-900">Sub Total</p>
                                        <p className="font-semibold text-gray-900">
                                          MK {(selectedItem.price * quantity).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                        </p>
                                    </div>
                                    <div className="flex items-center justify-between">
                                        <p className="text-sm font-medium text-gray-900">VAT</p>
                                        <p className="font-semibold text-gray-900">
                                        MK {(selectedItem.price * quantity).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                        </p>
                                    </div>
                                    <div className="flex items-center justify-between">
                                        <p className="text-sm font-medium text-gray-900">Total</p>
                                        <p className="font-semibold text-gray-900">
                                        MK {(selectedItem.price * quantity).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                        </p>
                                    </div>
                                </div>
                                <button className="mt-4 mb-8 w-full rounded-md bg-gray-900 px-6 py-3 font-medium text-white"
                                    onClick={() => handleAddToCart(selectedItem)}
                                >Add to Cart</button>
                            </div>
                        </div>
                    </>
                )}
            </Box>
        </Modal>
    );
};

export default AddToCartModal;