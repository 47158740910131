import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CustomerLayout from './Components/Layouts/Customer/CustomerLayout';
import MenuCard from './Components/OtherComponents/MenuCard';
import ShoppingCartFloat from './Components/OtherComponents/ShoppingCartFloat';
import { ShoppingCartState } from '../../utilities/Context/ShoppingCartContext';
import { Menu } from '../../domain/Types/Menu';
import { ShoppingCart } from '../../domain/Types/ShoppingCart';
import { fetchMenuData } from '../../infrastructure/api/Menus/MenuAPI';
import { Grid, Typography, Skeleton } from '@mui/material';
import AppLayout from './Components/Layouts/App/AppLayout';
import AddToCartModal from '../app/GeneralComponents/AddToCartModal';

const FoodStore = () => {
  const [Menus, setMenus] = useState<Menu[] | null>(null);
  const [loading, setLoading] = useState(true); // State to track loading
  const businessIDParam = useParams().BusinessID;
  const BusinessID: number = businessIDParam ? parseInt(businessIDParam) : NaN;
  const [openModal, setOpenModal] = useState(false); 
  const [selectedItem, setSelectedItem] = useState<Menu | null>(null); 
  const win = window.sessionStorage;
  const navigate = useNavigate();

  const userIdString = win.getItem("userid");
  const userId = userIdString ? parseInt(userIdString) : 0;

  const { dispatch } = ShoppingCartState();

  const handleAddtoCart = (menu: Menu) => {
    const cartitem: ShoppingCart = {
      id: menu.id,
      user_id: userId,
      session_id: menu.business_id,
      order_id: 1,
      menu_id: menu.id,
      imagepath: menu.image_path,
      status: menu.description,
      quantity: 1,
      total: menu.price,
      name: menu.name,
      price: menu.price,
      productattributevalues: [],
      businessId: menu.business_id,
      businessName: menu.business.name
    };

    dispatch({ type: 'ADD_TO_CART', item: cartitem });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchMenuData(BusinessID);
        setMenus(data);
        setLoading(false); // Stop loading once data is fetched
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false); // Stop loading on error
      }
    };

    fetchData();
  }, [BusinessID]);

  
  const handleOpenModal = (item: Menu) => {
    setSelectedItem(item);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedItem(null);
  };

  return (
    <AppLayout>
      <div className="font-bold mb-1">MENU</div>
      <Grid container>
        <Grid item md={9} container direction="row" spacing={2}>
          {loading ? (
            // Display Skeleton while loading
            Array.from(new Array(8)).map((_, index) => (
              <Grid key={index} item xs={12} md={3}>
                <Skeleton variant="rectangular" width="100%" height={200} />
                <Skeleton width="60%" height={30} />
                <Skeleton width="40%" height={30} />
              </Grid>
            ))
          ) : Menus && Menus.length > 0 ? (
            Menus.map((menu: Menu) => (
              <Grid key={menu.id} item xs={12} md={3}>
                <MenuCard
                  FoodMenu={menu}
                  onViewOption={() => navigate("/MenuOptions")}
                  //onSelectmenu={() => handleAddtoCart(menu)}
                  onSelectmenu={() => handleOpenModal(menu)}
                />
              </Grid>
            ))
          ) : (
            <Typography variant="h6" color="textSecondary">
              No Menus available
            </Typography>
          )}
        </Grid>
        <Grid item container xs={12} md={3}>
          <ShoppingCartFloat />
        </Grid>
      </Grid>
      {selectedItem && (
      <AddToCartModal 
      open={openModal} 
      handleClose={handleCloseModal}
      selectedItem={selectedItem} 
      userId={userId}
/>  
      )}      
    </AppLayout>
  );
};

export default FoodStore;