import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchBusinessbySlug } from '../../infrastructure/api/Business/BusinessAPI';
import { Search } from '../../domain/Types/Search';
import { Business } from '../../domain/Types/Business';
import AppLayout from './Components/Layouts/App/AppLayout';
import { Skeleton, Box, Typography, CardMedia } from '@mui/material';

const BusinessPage = () => {
  const { businessSlug } = useParams<{ businessSlug: string }>(); // Get the slug from the URL
  const [business, setBusiness] = useState<Business | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const searchCriteria: Search = {
      search: '',
      slug: businessSlug || '',
      BusinessCategory: '',
      location: '',
      foodtype: '',
      minimumPrice: 0,
      maximumprice: 0,
    };

    const fetchBusiness = async () => {
      try {
        const result = await fetchBusinessbySlug(searchCriteria); // Fetch business by slug
        setBusiness(result);
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message);
        } else {
          setError('An unknown error occurred');
        }
      } finally {
        setLoading(false);
      }
    };

    if (businessSlug) {
      fetchBusiness();
    }
  }, [businessSlug]);

  // Display skeleton loader while loading
  if (loading) {
    return (
      <AppLayout>
        <Box sx={{ padding: 2 }}>
          <Skeleton variant="rectangular" height={300} />
          <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
          <Skeleton variant="text" sx={{ fontSize: '1.2rem' }} />
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} width="50%" />
        </Box>
      </AppLayout>
    );
  }

  if (error) {
    return (
      <AppLayout>
        <Typography variant="h6" color="error">
          Error: {error}
        </Typography>
      </AppLayout>
    );
  }

  return (
    <AppLayout>
      {business ? (
        <Box sx={{ padding: 2 }}>
          {/* Business Image */}
          <CardMedia
            component="img"
            height="300"
            image={`https://app.foodhut.mw/Admin/public/images/${business.image_path}`}
            alt={business.name}
            sx={{
              borderRadius: 2,
              width: { xs: '100%', sm: '60%', md: '40%' },  // 100% on small screens, 60% on small devices, 40% on medium screens and larger
              height: 160,
              objectFit: 'cover',
            }}
          />

          {/* Business Info */}
          <Typography variant="h4" component="h1" sx={{ marginTop: 2 }}>
            {business.name}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {business.description}
          </Typography>

          <Typography variant="h6" component="p" sx={{ marginTop: 2 }}>
            Contact: {business.contactperson}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Email: {business.email}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Cell: {business.cellnumber}
          </Typography>
        </Box>
      ) : (
        <Typography variant="h6">Business not found.</Typography>
      )}
    </AppLayout>
  );
};

export default BusinessPage;