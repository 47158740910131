import React, { useState } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import AppLayout from '../customer/Components/Layouts/App/AppLayout';
import { TextField, Button, Grid, Typography, Box } from '@mui/material';

// Define the form input types
interface ForgotPasswordForm {
  email: string;
}

const ForgotPassword = () => {
  const { control, handleSubmit, formState: { errors } } = useForm<ForgotPasswordForm>();
  const [message, setMessage] = useState('');

  // Define the type for the form data
  const onSubmit: SubmitHandler<ForgotPasswordForm> = (data) => {
    // Logic to handle password reset (e.g., API call)
    setMessage('If the email is registered, a password reset link has been sent.');
  };

  return (
    <AppLayout>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        {/* Left Section: Illustration */}
        <Grid item xs={12} md={6}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <img src="/path/to/your/image.jpg" alt="Password Reset Illustration" style={{ width: '60%' }} />
          </Box>
        </Grid>

        {/* Right Section: Form */}
        <Grid item xs={12} md={6}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            style={{ padding: '2rem' }}
          >
            <Typography variant="h4" gutterBottom>
              Forgot Password
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              Enter your email and we'll send you a link to reset your password.
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)} style={{ width: '70%' }}>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                rules={{
                  required: 'Email is required',
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                    message: 'Invalid email address',
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Enter your email address"
                    variant="outlined"
                    type="email"
                    error={!!errors.email}
                    helperText={errors.email ? errors.email.message : ''}
                    style={{ marginBottom: '1rem' }}
                  />
                )}
              />
              {errors.email && (
                <Typography color="error">
                  {errors.email.message}
                </Typography>
              )}
              <Button type="submit" variant="contained" color="success" fullWidth style={{ marginTop: '1rem' }}>
                Submit
              </Button>
            </form>
            {message && (
              <Typography variant="body1" color="textSecondary" style={{ marginTop: '1rem' }}>
                {message}
              </Typography>
            )}
            <Button color="secondary" href="/login" style={{ marginTop: '1rem' }}>
              Back to Login
            </Button>
          </Box>
        </Grid>
      </Grid>
    </AppLayout>
  );
};

export default ForgotPassword;