import React, { useState } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { TextField, Button } from '@mui/material';
import AppLayout from '../customer/Components/Layouts/App/AppLayout';
import { useLocation } from 'react-router-dom';

// Define the form input types
interface ResetPasswordForm {
  password: string;
  confirmPassword: string;
}

const ResetPassword = () => {
  const { control, handleSubmit, formState: { errors }, watch } = useForm<ResetPasswordForm>();
  const [message, setMessage] = useState('');
  const location = useLocation(); // Used to grab the token from URL

  // Password validation function (checks if passwords match)
  const validatePassword = (value: string) => {
    const password = watch('password');
    return value === password || 'Passwords do not match';
  };

  // Extract token from URL query parameters
  const query = new URLSearchParams(location.search);
  const token = query.get('token');
  const email = query.get('email');

  console.log(token);
  console.log(email);

  // Define the type for the form data
  const onSubmit: SubmitHandler<ResetPasswordForm> = async (data) => {
    if (!token || !email) {
      setMessage('Invalid reset link');
      return;
    }

    try {
      // Call API to reset password
      const response = await fetch('/api/reset-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          password: data.password,
          password_confirmation: data.confirmPassword, // Make sure both passwords are sent
          token, // Send the token from the reset link
          email // Send the email from the reset link
        }),
      });

      if (response.ok) {
        setMessage('Your password has been reset successfully.');
      } else {
        const errorData = await response.json();
        setMessage(errorData.message || 'An error occurred. Please try again.');
      }
    } catch (error) {
      console.error('Error resetting password:', error);
      setMessage('An error occurred. Please try again later.');
    }
  };

  return (
    <AppLayout>
      <h2>Reset Password</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Controller
            name="password"
            control={control}
            defaultValue=""
            rules={{
              required: 'Password is required',
              minLength: {
                value: 6,
                message: 'Password must be at least 6 characters long',
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="New Password"
                variant="outlined"
                type="password"
                error={!!errors.password}
                helperText={errors.password ? errors.password.message : ''}
              />
            )}
          />
        </div>
        <div style={{ marginTop: '1rem' }}>
          <Controller
            name="confirmPassword"
            control={control}
            defaultValue=""
            rules={{
              required: 'Please confirm your password',
              validate: validatePassword,
            }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Confirm New Password"
                variant="outlined"
                type="password"
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword ? errors.confirmPassword.message : ''}
              />
            )}
          />
        </div>
        <Button type="submit" variant="contained" color="primary" style={{ marginTop: '1rem' }}>
          Reset Password
        </Button>
      </form>
      {message && <p>{message}</p>}
    </AppLayout>
  );
};

export default ResetPassword;