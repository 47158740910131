import axios, { AxiosResponse } from "axios";
import axiosRetry from "axios-retry";
import isOnline from 'is-online';
import { Menu } from "../../../domain/Types/Menu";
import { BASE_URL } from "../../../domain/Types/Config";
import { GeoSearch } from "../../../domain/Types/GeoSearch";
import { SearchMenu } from "../../../domain/Types/SearchMenu";
import { MenuSearch } from "../../../domain/Types/MenuSearch";
import { MenuCategory } from "../../../domain/Types/MenuCategory";
import { Search } from "../../../domain/Types/Search";

axiosRetry(axios, {
  retries: 3, // Number of retries
  retryDelay: axiosRetry.exponentialDelay, // Exponential backoff strategy
  shouldResetTimeout: true // Reset timeout on retries
});

export const fetchMenuData = async (BusinessID: number): Promise<Menu[]> => {
  try {
    // Check for internet connectivity
    const online = await isOnline();
    if (!online) {
      console.log("No internet connection. Retrying...");
      return fetchMenuData(BusinessID); // Retry fetching data
    }

    //const url = `https://app.foodhut.mw/api/ShowBusinessesMenus/${BusinessID}`
    const url = `${BASE_URL}/ShowBusinessesMenus/${BusinessID}`;
    const response: AxiosResponse<Menu[]> = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};


export const fetchSpecialDaysMenu = async (search: GeoSearch): Promise<Menu[]> => {
  try {
    // Check for internet connectivity
    const online = await isOnline();
    if (!online) {
      console.log("No internet connection. Retrying...");
      return fetchSpecialDaysMenu(search); // Retry fetching data
    }

    const url = `${BASE_URL}/ShowSpecialDaysMenu`;
    const response: AxiosResponse<Menu[]> = await axios.post(url, search);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const fetchPopularMenus = async (search: GeoSearch): Promise<Menu[]> => {
  try {
    const online = await isOnline();
    if (!online) {
      console.log("No internet connection. Retrying...");
      return fetchPopularMenus(search); // Retry fetching data
    }

    const url = `${BASE_URL}/ShowPopularMenus`;
    const response: AxiosResponse<Menu[]> = await axios.post(url,search);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};


export const SearchMenus = async (search: SearchMenu): Promise<Menu[]> => {
  try {
    const online = await isOnline();
    if (!online) {
      console.log("No internet connection. Retrying...");
      return SearchMenus(search); // Retry fetching data
    }

    const url = `${BASE_URL}/Menusearch`;
    const response: AxiosResponse<Menu[]> = await axios.post(url,search);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};


export const fetchAllMenuData = async (): Promise<Menu[]> => {
  try {
    // Check for internet connectivity
    const online = await isOnline();
    if (!online) {
      console.log("No internet connection. Retrying...");
      return fetchAllMenuData(); // Retry fetching data
    }

    //const url = `https://app.foodhut.mw/api/menus`
    const url = `${BASE_URL}/menus`;
    const response: AxiosResponse<Menu[]> = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const SingleProductPreview = async (): Promise<Menu[]> => {
  try {
    // Check for internet connectivity
    const online = await isOnline();
    if (!online) {
      console.log("No internet connection. Retrying...");
      return SingleProductPreview(); // Retry fetching data
    }

    //const url = `https://app.foodhut.mw/api/menus`
    const url = `${BASE_URL}/menus`;
    const response: AxiosResponse<Menu[]> = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const FetchMenuByCategory = async (Search: MenuSearch): Promise<Menu[]> => {
  try {
    // Check for internet connectivity
    const online = await isOnline();
    if (!online) {
      console.log("No internet connection. Retrying...");
      return FetchMenuByCategory(Search); // Retry fetching data
    }

    const url = `${BASE_URL}/MenusByCategory`;
    const response: AxiosResponse<Menu[]> = await axios.post(url,Search);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const FetchMenuCategories = async (): Promise<MenuCategory[]> => {
  try {
    // Check for internet connectivity
    const online = await isOnline();
    if (!online) {
      console.log("No internet connection. Retrying...");
      return FetchMenuCategories(); // Retry fetching data
    }

    const url = `${BASE_URL}/Menucategory`;
    const response: AxiosResponse<MenuCategory[]> = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const fetchMenuBySlug = async (searchCriteria: Search): Promise<Menu> => {
  try {
    // Check for internet connectivity
    const online = await isOnline();
    if (!online) {
      console.log("No internet connection. Retrying...");
      return fetchMenuBySlug(searchCriteria); // Retry fetching data
    }

    const url = `${BASE_URL}/SearchMenuBySlug`;
    const response: AxiosResponse<Menu> = await axios.post(url, searchCriteria);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

