import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RegisterUser, UpdateDeliveryDetails, UpdatePaymentDetails } from "../../infrastructure/api/User/UserAPI";
import { User } from "../../domain/Types/User";
import { TextField, Stepper, Step, StepLabel, Button, MenuItem, Checkbox, FormControlLabel, CircularProgress } from "@mui/material";
import { useForm, Controller, SubmitHandler, FieldValues } from "react-hook-form";
import AppLayout from "../customer/Components/Layouts/App/AppLayout";
import Swal from "sweetalert2";
import { Registration } from "../../domain/Types/Registration";
import { DeliveryDetails } from "../../domain/Types/DeliveryDetails";
import { paymentoption } from "../../domain/Types/paymentoption";

interface SummaryData {
  firstname?: string;
  lastname?: string;
  emailaddress?: string;
  paymentMethod?: string;
  AccountNumber?: string;
  provider?: string;
  mobileNumber?: string;
  useCurrentAddress?: boolean;
}

const Register = () => {
  const [activeStep, setActiveStep] = useState(() => parseInt(sessionStorage.getItem('activeStep') || '0'));
  const [loading, setLoading] = useState(false); // Loading state
  const [deliveryAddress, setDeliveryAddress] = useState("Address No Found");
  const [useCurrentAddress, setUseCurrentAddress] = useState(() => JSON.parse(sessionStorage.getItem('useCurrentAddress') || 'false'));
  const [summary, setSummary] = useState<SummaryData>(() => JSON.parse(sessionStorage.getItem('summary') || '{}'));
  const [latitude, setLatitude] = useState<number | null>(null);
  const [longitude, setLongitude] = useState<number | null>(null);
  const navigate = useNavigate();
  const win = window.sessionStorage;

  const validatePassword = (password: string) => password.length >= 6;
  const validateConfirmPassword = (confirmPassword: string, password: string) => confirmPassword === password || "Passwords do not match";

  const { control, handleSubmit, formState: { errors }, reset, watch } = useForm<FieldValues>();

  const password = watch('password', '');

  useEffect(() => {
    sessionStorage.setItem('activeStep', activeStep.toString());
    sessionStorage.setItem('summary', JSON.stringify(summary));
    sessionStorage.setItem('useCurrentAddress', JSON.stringify(useCurrentAddress));

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          setLatitude(lat);
          setLongitude(lng);

          try {
            const address = await getAddressFromCoordinates(lat, lng);
            setDeliveryAddress(address);
          } catch (error) {
            console.error('Error getting address:', error);
            // Handle error or fallback here
          }
        },
        (error) => {
          console.error("Error getting geolocation:", error);
          // Handle error or fallback here
        }
      );
    }
  }, [activeStep, summary, useCurrentAddress]);

  const getAddressFromCoordinates = async (latitude: number, longitude: number) => {
    const apiKey = 'AIzaSyDsidFtroyNq4iEbm1iZgZ2aJjymyfoE8E';
    const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`);
    const data = await response.json();

    if (data.status === 'OK') {
      return data.results[0]?.formatted_address || 'Address not found';
    } else {
      throw new Error('Error fetching address');
    }
  };

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    if (activeStep === steps.length - 1) {
      setLoading(true);
      const user: User = {
        id: 0,
        business_id: 0,
        firstname: summary.firstname || "",
        lastname: summary.lastname || "",
        email: summary.emailaddress || "",
        email_verified_at: summary.emailaddress || "",
        password: data.password,
        device_token: data.device_token,
        remember_token: "sdseweewe",
        acl: "customer",
        created_at: "12-12-12",
        updated_at: "12-12-12",
      };
      
      const deliverDetails: DeliveryDetails = {
        id: 1,
        user_id: user.id,
        delivery_address: useCurrentAddress ? deliveryAddress : "Address not found",
        latitude: latitude || 0, // Ensure a default value if latitude is null
        longitude: longitude || 0, // Ensure a default value if longitude is null
      };

      const paymentopt: paymentoption = {
        id: 1,
        user_id: user.id,
        type: summary.paymentMethod || "",
        provider: summary.provider  || "",
        account_number: summary.mobileNumber || "",
        phone_number: summary.mobileNumber || "",
        card_number: "",
        card_expiry: "",
        is_default: 1,
      };

      const register: Registration = {
        user: user,
        paymentOption: "",
        paymentNumber: "",
        deliverDetails: deliverDetails,
      };

      const UserRegister = async () => {
        try {
          const data = await RegisterUser(register.user); // The user saved save paymentoption and deliverydetails
          console.log(data);
          paymentopt.user_id = data.id;
          await UpdatePaymentDetails(paymentopt);
          deliverDetails.user_id = data.id;
          await UpdateDeliveryDetails(deliverDetails);

          Swal.fire({
            title: 'SUCCESS',
            text: 'Registration Successful',
            icon: 'success',
            showConfirmButton: false,
            timer: 1500,
          });

          win.setItem('Token', data.remember_token);
          win.setItem('userid', data.id.toString());
          win.setItem('name', data.firstname);
          win.setItem('email', data.email);
          win.setItem('userID', data.id.toString());
          setSummary(data);

          sessionStorage.clear(); // Clear session storage after successful registration
          navigate("/Signin");
        } catch (error) {
          console.error('Error registering user:', error);
        }
      finally {
        setLoading(false); // Stop loading spinner
      }
      };

      UserRegister();
      reset();
    } else {
      setSummary(prev => ({ ...prev, ...data, useCurrentAddress }));
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => setActiveStep(activeStep - 1);

  const steps = ["Register", "Payment Option", "Delivery Information", "Summary"];

  return (
    <AppLayout>
      <div className="page-section mb-60">
        <div className="container">
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={index}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          <form onSubmit={handleSubmit(onSubmit)}>
            {activeStep === 0 && (
              <div>
                <h4 className="login-title">Register</h4>
                <Controller
                  name="firstname"
                  control={control}
                  defaultValue={summary.firstname || ""}
                  rules={{ required: "First Name is required" }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="First Name"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      error={!!errors.firstname}
                      helperText={errors.firstname ? errors.firstname.message as string : ''}
                    />
                  )}
                />
                <Controller
                  name="lastname"
                  control={control}
                  defaultValue={summary.lastname || ""}
                  rules={{ required: "Last Name is required" }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Last Name"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      error={!!errors.lastname}
                      helperText={errors.lastname ? errors.lastname.message as string : ''}
                    />
                  )}
                />
                <Controller
                  name="emailaddress"
                  control={control}
                  defaultValue={summary.emailaddress || ""}
                  rules={{
                    required: "Email address is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address"
                    }
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Email Address"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      error={!!errors.emailaddress}
                      helperText={errors.emailaddress ? errors.emailaddress.message as string : ''}
                    />
                  )}
                />
                <Controller
                  name="password"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Password is required", validate: validatePassword }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="password"
                      label="Password"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      error={!!errors.password}
                      helperText={errors.password && "Password must be at least 6 characters long"}
                    />
                  )}
                />
                <Controller
                  name="confirmPassword"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Confirm Password is required",
                    validate: value => validateConfirmPassword(value, password)
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="password"
                      label="Confirm Password"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      error={!!errors.confirmPassword}
                      helperText={errors.confirmPassword ? errors.confirmPassword.message as string : ''}
                    />
                  )}
                />
              </div>
            )}

            {activeStep === 1 && (
              <div>
                <h4 className="login-title">Payment Option</h4>
                <Controller
                  name="paymentMethod"
                  control={control}
                  defaultValue={summary.paymentMethod || ""}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      select
                      label="Payment Method"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    >
                      {/*<MenuItem value="creditCard">Credit Card</MenuItem>
                      <MenuItem value="paypal">PayPal</MenuItem>*/}
                      <MenuItem value="mobilePayment">Mobile Payment</MenuItem>
                    </TextField>
                  )}
                />
               <Controller
                  name="provider"
                  control={control}
                  defaultValue={summary.provider || ""}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      select
                      label="Provider"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    >
                    <MenuItem value="AirtelMoney">Airtel Money</MenuItem>
                    <MenuItem value="TnmMpamba">TNM Mpamba</MenuItem>
                  </TextField>
                  )}
                />
                <Controller
                  name="mobileNumber"
                  control={control}
                  defaultValue={summary.mobileNumber || ""}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Mobile Number"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      type="tel"
                    />
                  )}
                />
              </div>
            )}

            {activeStep === 2 && (
              <div>
                <h4 className="login-title">Delivery Information</h4>
                <Controller
                  name="deliveryAddress"
                  control={control}
                  defaultValue={deliveryAddress}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Delivery Address"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  )}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={useCurrentAddress}
                      onChange={(e) => setUseCurrentAddress(e.target.checked)}
                    />
                  }
                  label="Use Current Address"
                />
              </div>
            )}

            {activeStep === 3 && (
              <div>
                <h4 className="login-title">Summary</h4>
                <p><strong>First Name:</strong> {summary.firstname}</p>
                <p><strong>Last Name:</strong> {summary.lastname}</p>
                <p><strong>Email Address:</strong> {summary.emailaddress}</p>
                <p><strong>Payment Method:</strong> {summary.paymentMethod}</p>
                <p><strong>Provider:</strong> {summary.provider}</p>
                <p><strong>Mobile Number:</strong> {summary.mobileNumber}</p>
                <p><strong>Delivery Address:</strong> {useCurrentAddress ? deliveryAddress : "New Address"}</p>
                <p><strong>Latitude:</strong> {latitude}</p>
                <p><strong>Longitude:</strong> {longitude}</p>
              </div>
            )}

            <div className="form-actions">
              {activeStep > 0 && (
                <Button onClick={handleBack}>Back</Button>
              )}
              <Button type="submit" variant="outlined">
                {loading ? <CircularProgress size={24} /> : activeStep === steps.length - 1 ? "Register" : "Next"}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </AppLayout>
  );
};

export default Register;