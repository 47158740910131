import React, { useEffect, useState } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { TextField, MenuItem, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import { useContext } from 'react';
import { ShoppingCartContext } from '../../../../utilities/Context/ShoppingCartContext';
import { ShoppingCart } from '../../../../domain/Types/ShoppingCart';
import { UserSearch } from '../../../../domain/Types/UserSearch';
import { fetchUserPaymentOptions } from '../../../../infrastructure/api/Payment/PaymentAPI';
import { paymentoption } from '../../../../domain/Types/paymentoption';

interface FormData {
    PaymentMethod: string;
    MobileProvider: string;
    AccountNumber: string;
    PhoneNumber:string;
    CardType:string;
    CardExpiryDate:string;
    CardNumber:string;
    PaymentRef: string;
    VisaCardNumber?: string;
    VisaExpiryDate?: string;
    AirtelMoneyNumber?: string;
    MpambaNumber?: string;
    deliveryOption: 'pick-up' | 'delivery';
    DeliveryAddress?: string;
    FirstName: string;
    LastName: string;
    email: string;
    cellnumber: string;
    TotalPrice: number;
    VAT: number;
    TotalPriceIncludingVAT: number;
    DeliveryFee: number;
    cartitems: ShoppingCart[];
}

interface PaymentFormProps {
  onSubmit: SubmitHandler<FormData>;
}

const PaymentConfirmForm: React.FC<PaymentFormProps> = ({ onSubmit }) => {

const [paymentOptions, setPaymentOptions] = useState<paymentoption[]>([]); // State to store payment options
// Fetch UserPaymentOptions on component load
useEffect(() => {
  const Search: UserSearch = {
    UserID: 2,
    firstname: "",
    lastname: "",
    email: "",
    acl: "",
    device_token: ""
  }

  const fetchUserPaymentOpt = async (Search: UserSearch) => {
    try {
      const options = await fetchUserPaymentOptions(Search);
      setPaymentOptions(options); // Store fetched options in state
      console.log(paymentOptions);
    } catch (error) {
      console.error('Error fetching payment options:', error);
    }
  };

  fetchUserPaymentOpt(Search);
}, []);

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormData>();

  const { shoppingcart, getTotalPrice, calculateVAT, calculateTotalPrice, selectedBusinessId } = useContext(ShoppingCartContext);

  // Filter cart items based on the selected business ID
  const filteredCartItems = selectedBusinessId 
    ? shoppingcart.filter(item => item.businessId === selectedBusinessId) 
    : shoppingcart;

  const totalPrice = getTotalPrice(filteredCartItems);
  const VAT = calculateVAT(totalPrice);
  const totalPriceIncludingVAT = calculateTotalPrice(totalPrice, VAT);
  const DeliveryFee = 0;

  const formattedTotalPrice = `MK ${totalPrice.toFixed(2)}`;
  const formattedVAT = `MK ${VAT.toFixed(2)}`;
  const formattedTotalPriceIncludingVAT = `MK ${totalPriceIncludingVAT.toFixed(2)}`;
  const formattedDeliveryFee = `MK ${DeliveryFee.toFixed(2)}`;

  const selectedPaymentMethod = watch('PaymentMethod');
  const selectedDeliveryOption = watch('deliveryOption');

  const handleFormSubmit: SubmitHandler<FormData> = (data) => {
    const customerDetails = {
        FirstName: window.sessionStorage.getItem('fname') || '',
        LastName: window.sessionStorage.getItem('lname') || '',
        email: window.sessionStorage.getItem('email') || '',
        cellnumber: window.sessionStorage.getItem('phone') || '',
        TotalPrice: totalPrice,
        VAT: VAT,
        TotalPriceIncludingVAT: totalPriceIncludingVAT,
        DeliveryFees: DeliveryFee,
        cartitems: filteredCartItems,
    };

    onSubmit({ ...data, ...customerDetails });
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} className="mt-2 grid gap-6">
      <div className="grid sm:px-10 lg:grid-cols-2 lg:px-20 xl:px-32">
        <div className="mt-10 bg-gray-50 px-4 pt-8 lg:mt-0">
          <p className="text-xl font-medium border-b">Customer Details</p>
          <div>
            <p>
              <span className="text-lg font-bold">First Name: </span>
              <span className="text-lg">{window.sessionStorage.getItem('fname')}</span>
            </p>
            <p>
              <span className="text-lg font-bold">Last Name: </span>
              <span className="text-lg">{window.sessionStorage.getItem('lname')}</span>
            </p>
            <p>
              <span className="text-lg font-bold">Email: </span>
              <span className="text-lg">{window.sessionStorage.getItem('email')}</span>
            </p>
          </div>
          <p className="text-xl border-t mt-6 mb-4 font-medium">Payment Details</p>
          <div>
            <label htmlFor="card-holder" className="mt-4 mb-2 block text-sm font-medium">Payment Type</label>
            <Controller
              name="PaymentMethod"
              control={control}
              defaultValue=""
              rules={{ required: 'Payment Method is required' }}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  id="payment-method"
                  label="Select Payment Type"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  error={!!errors.PaymentMethod}
                  helperText={errors.PaymentMethod ? errors.PaymentMethod.message as string : ''}
                >
                  <MenuItem value="">Select a Payment Provider</MenuItem>
                  {paymentOptions.map((option) => (
                    <MenuItem key={option.id} value={option.type}>
                      {option.type}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            

            {selectedPaymentMethod === 'mobilePayment' && (
              <>
                      <Controller
                              name="MobileProvider"
                              control={control}
                              defaultValue=""
                              rules={{ required: 'Mobile Provider is required' }}
                              render={({ field }) => (
                                <TextField
                                {...field}
                                select
                                id="mobile-provider"
                                label="Select Mobile Provider"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                error={!!errors.MobileProvider}
                                helperText={errors.MobileProvider ? errors.MobileProvider.message as string : ''}
                              >
                                  <MenuItem value="">Select a Payment Provider</MenuItem>
                                  {paymentOptions.map((option) => (
                                    <MenuItem key={option.id} value={option.provider}>
                                      {option.provider}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              )}
                            />

            <Controller
              name="AccountNumber"
              control={control}
              rules={{ required: 'AccountNumber is required' }}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  id="AccountNumber"
                  label="Select Account Number"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  error={!!errors.AccountNumber}
                  helperText={errors.AccountNumber ? errors.AccountNumber.message as string : ''}
                >
                  <MenuItem value="">Select a Account Number</MenuItem>
                  {paymentOptions.map((option) => (
                    <MenuItem key={option.id} value={option.account_number}>
                      {option.account_number}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />                

                
              </>
            )}

            {selectedPaymentMethod === 'bankTransfer' && (
              <>
                <Controller
                  name="CardType"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'Card Type is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      select
                      id="card-type"
                      label="Select Card Type"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      error={!!errors.CardType}
                      helperText={errors.CardType ? errors.CardType.message as string : ''}
                    >
                      <MenuItem value="">Select Card Type</MenuItem>
                      <MenuItem value="VisaCard">Visa</MenuItem>
                      <MenuItem value="MasterCard">MasterCard</MenuItem>
                      <MenuItem value="ExpressCard">ExpressCard</MenuItem>
                    </TextField>
                  )}
                />

                <Controller
                  name="CardNumber"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'Card Number is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="card-number"
                      label="Card Number"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      error={!!errors.CardNumber}
                      helperText={errors.CardNumber ? errors.CardNumber.message as string : ''}
                    />
                  )}
                />

                <Controller
                  name="CardExpiryDate"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'Card Expiry Date is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="card-expiry"
                      label="Card Expiry Date"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      error={!!errors.CardExpiryDate}
                      helperText={errors.CardExpiryDate ? errors.CardExpiryDate.message as string : ''}
                    />
                  )}
                />
              </>
            )}           

            {selectedPaymentMethod === 'Visa' && (
              <>
                <Controller
                  name="VisaCardNumber"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'Visa Card Number is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="visaCardNumber"
                      label="Visa Card Number"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      error={!!errors.VisaCardNumber}
                      helperText={errors.VisaCardNumber ? errors.VisaCardNumber.message as string : ''}
                    />
                  )}
                />

                <Controller
                  name="VisaExpiryDate"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'Visa Expiry Date is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="visaExpiryDate"
                      label="Visa Expiry Date"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      error={!!errors.VisaExpiryDate}
                      helperText={errors.VisaExpiryDate ? errors.VisaExpiryDate.message as string : ''}
                    />
                  )}
                />
              </>
            )}

            {selectedPaymentMethod === 'AirtelMoney' && (
              <>
                <Controller
                  name="AirtelMoneyNumber"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'Airtel Money Number is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="airtelMoneyNumber"
                      label="Airtel Money Number"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      error={!!errors.AirtelMoneyNumber}
                      helperText={errors.AirtelMoneyNumber ? errors.AirtelMoneyNumber.message as string : ''}
                    />
                  )}
                />
              </>
            )}

            {selectedPaymentMethod === 'Mpamba' && (
              <>
                <Controller
                  name="MpambaNumber"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'Mpamba Number is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="mpambaNumber"
                      label="Mpamba Number"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      error={!!errors.MpambaNumber}
                      helperText={errors.MpambaNumber ? errors.MpambaNumber.message as string : ''}
                    />
                  )}
                />
              </>
            )}
          

              <Controller
              name="PaymentRef"
              control={control}
              defaultValue=""
              rules={{ required: 'Payment Reference#' }}
              render={({ field }) => (
                <TextField
                  {...field}
                  id="PaymentRef"
                  label="Payment Reference Number"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  error={!!errors.PaymentRef}
                  helperText={errors.PaymentRef ? errors.PaymentRef.message as string : ''}
                />
              )}
            />
          </div>

          <p className="mt-6 mb-4 border-t text-lg font-medium">Delivery Methods</p>
          <Controller
            name="deliveryOption"
            control={control}
            defaultValue="pick-up"
            rules={{ required: 'Delivery option is required' }}
            render={({ field }) => (
              <RadioGroup {...field}>
                <FormControlLabel value="pick-up" control={<Radio />} label="Pick-up" />
                <FormControlLabel value="delivery" control={<Radio />} label="Delivery" />
              </RadioGroup>
            )}
          />

          {selectedDeliveryOption === 'delivery' && (
            <>
              <Controller
                name="DeliveryAddress"
                control={control}
                defaultValue=""
                rules={{ required: 'Delivery Address is required' }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="DeliveryAddress"
                    label="Delivery Address"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={!!errors.DeliveryAddress}
                    helperText={errors.DeliveryAddress ? errors.DeliveryAddress.message as string : ''}
                  />
                )}
              />
            </>
          )}
        </div>

        <div className="px-4 rounded-lg border">
          <p className="text-xl font-medium">Order Summary</p>
          <div className="mt-8 space-y-3 rounded-lg border bg-white px-2 sm:px-6">
            {filteredCartItems.length > 0 ? (
              <>
                {filteredCartItems.map((product) => (
                  <div key={product.id} className="flex flex-col rounded-lg bg-white sm:flex-row">
                    <div className="flex w-full flex-col px-4">
                      <span className="font-semibold">{product.name} (<span className="font-bold">MK {product.price}</span>)</span>
                      <span className="float-right text-gray-400">{product.status}</span>
                      <span className="text-lg font-bold">Qty {product.quantity}, Total: MK {product.price * product.quantity}</span>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <div>
                <p>No items available in the cart.</p>
              </div>
            )}
          </div>
          <div className="mt-6 border-t border-b py-2">
            <div className="flex items-center justify-between">
              <p className="text-sm font-medium text-gray-900">Subtotal</p>
              <p className="font-semibold text-gray-900">MK{formattedTotalPrice}</p>
            </div>
            <div className="flex items-center justify-between">
              <p className="text-sm font-medium text-gray-900">VAT</p>
              <p className="font-semibold text-gray-900">MK{formattedVAT}</p>
            </div>        
            <div className="flex items-center justify-between">
              <p className="text-sm font-medium text-gray-900">Delivery</p>
              <p className="font-semibold text-gray-900">MK{formattedDeliveryFee}</p>
            </div>
          </div>
          <div className="mt-6 flex items-center justify-between">
            <p className="text-sm font-medium text-gray-900">Total</p>
            <p className="text-2xl font-semibold text-gray-900">MK{formattedTotalPriceIncludingVAT}</p>
          </div>    
          <button className="mt-4 mb-8 w-full rounded-md bg-gray-900 px-6 py-3 font-medium text-white">Place Order</button>
        </div>  
      </div>
    </form>
  );
};

export default PaymentConfirmForm;